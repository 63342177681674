export function PersonalIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.069 14C15.8782 14 18.9655 10.8659 18.9655 7C18.9655 3.13414 15.8782 0 12.069 0C8.2597 0 5.17241 3.13414 5.17241 7C5.17241 10.8659 8.2597 14 12.069 14ZM12.069 1.75C14.9213 1.75 17.2414 4.10484 17.2414 7C17.2414 9.89461 14.9213 12.25 12.069 12.25C9.2166 12.25 6.89655 9.89297 6.89655 7C6.89655 4.10484 9.21875 1.75 12.069 1.75ZM14.8006 16.625H9.33729C4.18157 16.625 0 20.8687 0 26.1023C0 27.1491 0.836207 27.9984 1.86746 27.9984H22.2716C23.3028 28 24.1379 27.1523 24.1379 26.1023C24.1379 20.8687 19.9569 16.625 14.8006 16.625ZM22.2683 26.25H1.86746C1.78879 26.25 1.72414 26.1844 1.72414 26.1023C1.72414 21.8422 5.14009 18.375 9.33729 18.375H14.7953C18.9978 18.375 22.4138 21.8422 22.4138 26.1023C22.4138 26.1844 22.3491 26.25 22.2683 26.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
