import { useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';

/**
 * Install a hook to recalculate simple bar layout
 * Useful when we mount simplebar in a component that
 * transition during the load
 * @param timer
 */
export function useSimpleBarRefresher(timer = 1000) {
  const ref = useRef<SimpleBar | null>(null);
  useEffect(() => {
    const install = setTimeout(() => {
      if (ref.current) {
        ref.current.recalculate();
      }
    }, timer);
    return () => clearTimeout(install);
  }, [timer, ref.current]);
  return { ref };
}
