export function RemoteIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5868 11.0839C28.0778 11.4989 28.141 12.2275 27.7229 12.7148C27.3049 13.2022 26.5708 13.2649 26.0799 12.85L24.8889 11.8463V20.8456C24.8889 22.9784 23.1486 24.7059 21 24.7059H7C4.85236 24.7059 3.11111 22.9784 3.11111 20.8456V11.8463L1.92159 12.85C1.43014 13.2649 0.694165 13.2022 0.277081 12.7148C-0.139759 12.2275 -0.0794326 11.4989 0.41188 11.0839L13.2465 0.275046C13.6792 -0.091682 14.3208 -0.091682 14.7535 0.275046L27.5868 11.0839ZM7 22.3897H9.33333V15.0551C9.33333 13.9887 10.2035 13.125 11.2778 13.125H16.7222C17.7965 13.125 18.6667 13.9887 18.6667 15.0551V22.3897H21C21.8604 22.3897 22.5556 21.6997 22.5556 20.8456V9.88235L14 2.67663L5.44444 9.88235V20.8456C5.44444 21.6997 6.13958 22.3897 7 22.3897ZM11.6667 22.3897H16.3333V15.4412H11.6667V22.3897Z"
        fill="currentColor"
      />
    </svg>
  );
}
