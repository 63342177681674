import React, { KeyboardEventHandler, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { BiPencil } from 'react-icons/bi';
import { classNames } from '@/lib/classNames';
import { Input } from '@/components/basic';
import {
  IFormPolicy,
  WizardStepProps
} from '@/components/attendance/admin/wizard/useLeavePoliciesWizard';
import { WizardGoBack } from '@/components/wizard/WizardGoBack';

type Props = {
  title: string;
  description: string;
  icon?: React.FC<{ className: string }> | null;
  firstStep: boolean;
  register: UseFormRegister<IFormPolicy>;
} & WizardStepProps;
export function LeavePoliciesStepHeader({
  title,
  description,
  icon: Icon,
  firstStep,
  register,
  dispatch
}: Props) {
  const [edit, setEdit] = useState(false);
  const previous = () => {
    dispatch({ type: 'prev-step' });
  };
  const toggleEdit: KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      setEdit((x) => !x);
    }
  };

  return (
    <div>
      {!firstStep ? <WizardGoBack onClick={previous} /> : null}
      <h2
        className={classNames(
          !firstStep ? 'mt-4' : '',
          'text-xl flex items-center '
        )}
      >
        {firstStep && Icon ? <Icon className="w-5 h-5 text-primary " /> : null}
        <span className={classNames(firstStep ? 'mx-2' : '', 'text-tertiary')}>
          {edit ? (
            <Input
              placeholder="Policy Title"
              onKeyDown={toggleEdit}
              type="text"
              {...register('title')}
            />
          ) : (
            title
          )}
        </span>
        {firstStep ? (
          <button
            type="button"
            onClick={() => setEdit((x) => !x)}
            className=" flex items-center"
          >
            <BiPencil className="w-4 h-4" />
          </button>
        ) : null}
      </h2>
      <p className="mt-2 text-tertiary leading-normal text-base w-80">
        {edit ? (
          <Input
            placeholder="Policy Description"
            onKeyDown={toggleEdit}
            type="text"
            {...register('description')}
          />
        ) : (
          description
        )}
      </p>
    </div>
  );
}
