import LoadingSpinner from '@/components/util/LoadingSpinner';
import Scrollable from '@/components/util/Scrollable';
import { classNames } from '@/lib/classNames';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  XIcon
} from '@heroicons/react/solid';
import { Popover } from '@mantine/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BiCheck, BiX } from 'react-icons/bi';

type Props = {
  options: { label: string; value: string }[];
  values: string[];
  onChange?: (x: string[]) => void;
  onQueryUpdate?: (x: string) => void;
  nonePlaceholder?: string;
  allPlaceholder?: string;
  className?: string;
  disabled?: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
  maxAllowed?: number;
  applyOnClick?: boolean;
};

function updateToggleFn(item: { value: string }, maxAllowed?: number) {
  return (selected: string[]) => {
    if (selected.includes(item.value)) {
      return selected.filter((x) => x !== item.value);
    }
    if (typeof maxAllowed !== 'undefined' && selected.length >= maxAllowed) {
      return [...selected.splice(0, 1), item.value];
    }
    return [...selected, item.value];
  };
}

export default function SelectMenu({
  options,
  values,
  onChange,
  onQueryUpdate,
  allPlaceholder = 'All',
  nonePlaceholder = 'None',
  className = 'w-60',
  disabled = false,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  applyOnClick,
  maxAllowed
}: Props) {
  const refInput = useRef<HTMLInputElement>(null);
  const [opened, setOpened] = useState(false);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState(values);
  useEffect(() => {
    if (onQueryUpdate) {
      onQueryUpdate(search);
    }
  }, [search, onQueryUpdate]);
  useEffect(() => {
    if (opened) {
      setSearch('');
      setTimeout(() => refInput.current?.focus(), 400);
    } else {
      !applyOnClick && onChange?.(selected);
    }
  }, [opened, applyOnClick]);
  useEffect(() => {
    setSelected(values);
  }, [values]);

  const hasChanges = useMemo(() => {
    return (
      selected.length !== values.length ||
      selected.some((x) => !values.includes(x))
    );
  }, [selected, values]);

  useEffect(() => {
    if (hasChanges && maxAllowed === 1) {
      setOpened(false);
    }
  }, [hasChanges, maxAllowed]);

  const firstOption = values.length
    ? options.find((x) => x.value === values[0])
    : null;
  const realPlaceHolder =
    values.length === options.length &&
    !hasNextPage &&
    maxAllowed !== 1 &&
    !search.length
      ? allPlaceholder
      : values.length > 1
        ? `${values.length} selected`
        : values.length === 0
          ? nonePlaceholder
          : firstOption?.label ?? '';
  const visibleInputs = options.filter((x) =>
    !search.length ? true : x.label.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={className}>
      <Popover
        opened={opened}
        onChange={setOpened}
        arrowSize={0}
        width={'target'}
        position="bottom-start"
        withArrow
        shadow="md"
      >
        <Popover.Target>
          {opened ? (
            <div className="relative">
              <input
                className={classNames(
                  disabled ? 'cursor-disabled bg-gray-100' : 'cursor-pointer',
                  'flex w-full select-none items-center justify-between rounded-md border border-gray-300  py-2 pl-3 focus:border-primary focus:ring-primary'
                )}
                placeholder="Search..."
                ref={refInput}
                type="search"
                value={search}
                disabled={disabled}
                onChange={(ev) => setSearch(ev.currentTarget.value)}
              />
              {search.length ? (
                <XIcon
                  onClick={() => setSearch('')}
                  className="absolute top-3 right-2 z-10 h-5 w-5 cursor-pointer text-gray-400"
                />
              ) : null}
            </div>
          ) : (
            <div
              onClick={() => !disabled && setOpened((o) => !o)}
              className={classNames(
                disabled
                  ? 'cursor-not-allowed bg-gray-100 text-gray-300'
                  : 'cursor-pointer',
                'custom-tactic-select flex select-none items-center justify-between rounded-md border border-gray-300 py-2 pl-3'
              )}
            >
              <span
                title={realPlaceHolder}
                className="grow truncate border-r border-gray-300 leading-normal"
              >
                {realPlaceHolder}
              </span>
              <ChevronDownIcon className="h-5 w-8 text-gray-300" />
            </div>
          )}
        </Popover.Target>
        <Popover.Dropdown className="select-none rounded-md p-0">
          <div
            className={classNames(
              typeof maxAllowed !== 'undefined'
                ? 'hidden'
                : 'border-tacticGray-100 flex w-full justify-between space-x-4 border-b px-4 py-2 text-sm'
            )}
          >
            <div>{selected.length} selected</div>
            <div className="flex space-x-4">
              <button
                className="text-primary"
                onClick={(ev) => {
                  ev.preventDefault();
                  setSelected(visibleInputs.map((x) => x.value));
                }}
              >
                All
              </button>

              <button
                className="text-primary"
                onClick={(ev) => {
                  ev.preventDefault();
                  setSelected(
                    options
                      .filter(
                        (x) =>
                          !visibleInputs.map((y) => y.value).includes(x.value)
                      )
                      .map((x) => x.value)
                  );
                }}
              >
                None
              </button>
            </div>
          </div>
          <Scrollable maxHeight={'300px'}>
            <div className="w-full divide-y divide-gray-200 border-b border-gray-200">
              {visibleInputs.map((item) => {
                const checked = selected.includes(item.value);
                return (
                  <label
                    title={item.label}
                    className={classNames(
                      'flex h-10 cursor-pointer items-center space-x-2',
                      maxAllowed === 1 ? 'px-0' : 'px-4',
                      maxAllowed === 1 && checked ? 'text-primary' : ''
                    )}
                    key={item.value}
                    htmlFor={`checkbox-list-${item.value}`}
                  >
                    <input
                      onChange={() => {
                        setSelected(updateToggleFn(item, maxAllowed));
                      }}
                      id={`checkbox-list-${item.value}`}
                      type="checkbox"
                      checked={checked}
                      className={classNames(
                        maxAllowed === 1
                          ? 'hidden'
                          : 'rounded checked:bg-primary checked:hover:bg-primary-hover focus:ring-0 focus:ring-primary checked:focus:bg-primary'
                      )}
                    />

                    {maxAllowed === 1 ? (
                      <CheckCircleIcon
                        className={classNames(
                          'h-5 w-5 text-primary',
                          checked ? '' : 'invisible'
                        )}
                      />
                    ) : null}

                    <span className="truncate">{item.label}</span>
                  </label>
                );
              })}
            </div>
          </Scrollable>
          <div
            className={classNames(
              'border-tacticGray-100 flex w-full justify-center space-x-4   px-4 text-sm transition-all duration-100',
              !search.length && hasNextPage && fetchNextPage
                ? 'border-t py-2 opacity-100'
                : 'h-0 opacity-0'
            )}
          >
            <button
              disabled={!hasNextPage || !fetchNextPage || isFetchingNextPage}
              className="flex items-center space-x-1 text-primary"
              onClick={(ev) => {
                ev.preventDefault();
                if (fetchNextPage) {
                  fetchNextPage();
                }
              }}
            >
              {isFetchingNextPage ? <LoadingSpinner /> : 'Load more'}
            </button>
          </div>

          <div
            className={classNames(
              'border-tacticGray-100 flex w-full justify-end space-x-4   px-4 text-sm transition-all duration-100',
              hasChanges ? 'border-t py-2 opacity-100' : 'h-0 opacity-0'
            )}
          >
            <button
              className="text-tertiary flex items-center space-x-1"
              onClick={(ev) => {
                ev.preventDefault();
                setSelected(values);
                setOpened(false);
              }}
            >
              <BiX className="h-5 w-5" />
              <span>Cancel</span>
            </button>
            <button
              className="flex items-center space-x-1 text-primary"
              onClick={(ev) => {
                ev.preventDefault();
                setOpened(false);
                applyOnClick && onChange?.(selected);
              }}
            >
              <BiCheck className="h-5 w-5" />
              <span>Apply</span>
            </button>
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}
