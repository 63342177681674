import SelectMenu from '@/components/basic/SelectMenu/SelectMenu';
import { useUserListByIds, useUserListPaginated } from '@/hooks/useUserList';
import { useEffect, useMemo, useState } from 'react';

type Props = {
  onChange: (ids: string[]) => void;
  values?: string[];
  className?: string;
  applyOnClick?: boolean;
};

export const SelectUsersBeta2 = ({
  className,
  onChange,
  values = [],
  applyOnClick
}: Props) => {
  const [missingUsers, setMissingUsers] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const userList = useUserListPaginated(query, 10);
  const users = userList.allPages;
  const userIncludeList = useUserListByIds(missingUsers);
  useEffect(() => {
    if (userList.isFetching) {
      return;
    }
    const missing = values.filter((x) => !users.some((y) => y.id === x));
    setMissingUsers(missing);
  }, [values, userList.isFetching, users]);
  const options = useMemo(() => {
    // create options and remove duplicates
    return users
      .concat(userIncludeList.data ?? [])
      .map((x) => ({ label: x.name, value: x.id }))
      .filter((x, i, arr) => arr.findIndex((y) => y.value === x.value) === i);
  }, [users, userIncludeList.data]);

  return (
    <>
      <SelectMenu
        className={className}
        applyOnClick={applyOnClick}
        nonePlaceholder={'Select Employees'}
        allPlaceholder={'All Employees'}
        options={options}
        values={values}
        onChange={(x) => onChange(x)}
        onQueryUpdate={(x) => setQuery(x)}
        hasNextPage={userList.hasNextPage}
        fetchNextPage={userList.fetchNextPage}
        isFetchingNextPage={userList.isFetchingNextPage}
      />
    </>
  );
};
