import { CheckIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Menu } from '@mantine/core';
import React, { useState } from 'react';
import { classNames } from '@/lib/classNames';

type Props = {
  value: string;
  setValue: (x: string) => void;
  options: { label: string; value: string }[];
  readonly?: boolean;
  className?: string;
};

export function SelectMenuSingle({
  value,
  setValue,
  readonly = false,
  options,
  className = 'w-40'
}: Props) {
  const [opened, setOpened] = useState(false);
  const currentOption = options.find((x) => x.value === value);

  return (
    <div className={className}>
      <Menu
        opened={!readonly ? opened : false}
        onChange={setOpened}
        shadow={'md'}
        width="target"
      >
        <Menu.Target>
          <button
            onClick={(ev) => {
              ev.preventDefault();
            }}
            className={classNames(
              readonly
                ? 'cursor-default'
                : 'cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2',
              'custom-tactic-select flex w-full select-none items-center justify-between rounded-md border border-gray-300 py-2 pl-3'
            )}
          >
            <span
              title={currentOption?.label ?? '-'}
              className="flex grow items-center truncate border-r border-gray-300"
            >
              <span className="overflow-hidden text-ellipsis whitespace-nowrap leading-normal">
                {currentOption?.label ?? '-'}
              </span>
            </span>

            <ChevronDownIcon className=" h-5 w-8 text-gray-300 " />
          </button>
        </Menu.Target>

        <Menu.Dropdown>
          {options.map((option) => (
            <Menu.Item
              key={option.value}
              onClick={() => {
                setValue(option.value);
              }}
              rightSection={
                option.value === value ? (
                  <CheckIcon className="h-5 w-5 text-primary" />
                ) : null
              }
            >
              {option.label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
