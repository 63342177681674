export function BereavementIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1195 15.75H24.535C20.761 15.75 17.2014 17.4792 14.8717 20.4531V15.75C19.2199 15.75 22.7467 12.2227 22.7467 7.875V0.875C22.7467 0.54693 22.5621 0.246094 22.2682 0.0957031C21.9741 -0.0546875 21.6185 -0.0205078 21.3521 0.170898L17.3141 3.10352L14.6683 0.314453C14.5086 0.116211 14.2625 0 14 0C13.7375 0 13.4914 0.116211 13.3273 0.314453L10.6816 3.10352L6.64453 0.170898C6.37656 -0.0205078 6.0211 -0.0546875 5.72578 0.0957031C5.4332 0.246094 5.24891 0.546875 5.24891 0.875V7.875C5.24891 12.2227 8.7768 15.75 13.1239 15.75V20.4531C10.8008 17.4781 7.24063 15.75 3.46445 15.75H0.879923C0.380736 15.75 -0.0499939 16.2422 0.00469912 16.8219C0.544798 23.0836 5.81875 28 12.2445 28H15.7588C22.1845 28 27.4564 23.0847 27.9978 16.8219C28.0492 16.2422 27.6172 15.75 27.1195 15.75ZM6.95078 7.875V2.59109L10.8478 5.42117L14 2.14648L17.1041 5.42117L21.0011 2.59109V7.875C21.0011 11.2591 18.2596 14 14.8761 14H13.0758C9.73985 14 6.95078 11.2602 6.95078 7.875ZM13.0758 26.25H12.2445C7.10992 26.25 2.69063 22.4695 1.85391 17.5H3.46719C7.16625 17.5 10.593 19.4348 12.4906 22.6067C13.1852 23.8492 13.0758 24.8281 13.0758 26.25ZM15.75 26.25H14.875C14.875 24.8773 14.7656 23.8503 15.511 22.6062C17.4125 19.4359 20.8359 17.5 24.5383 17.5H26.1516C25.3094 22.4711 20.8906 26.25 15.75 26.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
