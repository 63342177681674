import React, { useMemo, useReducer } from 'react';
import { LeavePolicy, LeavePolicyType, WeekDayNames } from '@gettactic/api';
import { useAuthenticated } from '@/lib/api/appUser';
import { convertTZ, dateParseISO, parseApiDate } from '@/lib/utils';
import LeavePolicyAssignmentStep from '@/components/attendance/admin/wizard/shared/LeavePolicyAssignmentStep';
import LeavePolicyBereavementStep01 from '@/components/attendance/admin/wizard/shared/LeavePolicyBereavementStep01';
import LeavePolicyCustomStep01 from '@/components/attendance/admin/wizard/shared/LeavePolicyCustomStep01';
import LeavePolicyOfficeStep01 from '@/components/attendance/admin/wizard/shared/LeavePolicyOfficeStep01';
import LeavePolicyParentalStep01 from '@/components/attendance/admin/wizard/shared/LeavePolicyParentalStep01';
import LeavePolicyPersonalStep01 from '@/components/attendance/admin/wizard/shared/LeavePolicyPersonalStep01';
import LeavePolicyRemoteStep01 from '@/components/attendance/admin/wizard/shared/LeavePolicyRemoteStep01';
import LeavePolicySelectTypeStep00 from '@/components/attendance/admin/wizard/shared/LeavePolicySelectTypeStep00';
import LeavePolicySickStep01 from '@/components/attendance/admin/wizard/shared/LeavePolicySickStep01';
import LeavePolicyUnpaidStep01 from '@/components/attendance/admin/wizard/shared/LeavePolicyUnpaidStep01';
import LeavePolicyVacationStep01 from '@/components/attendance/admin/wizard/shared/LeavePolicyVacationStep01';
import { BereavementIcon } from '@/components/attendance/icons/BereavementIcon';
import { CustomIcon } from '@/components/attendance/icons/CustomIcon';
import { OfficeIcon } from '@/components/attendance/icons/OfficeIcon';
import { ParentalIcon } from '@/components/attendance/icons/ParentalIcon';
import { PersonalIcon } from '@/components/attendance/icons/PersonalIcon';
import { RemoteIcon } from '@/components/attendance/icons/RemoteIcon';
import { SickIcon } from '@/components/attendance/icons/SickIcon';
import { UnpaidIcon } from '@/components/attendance/icons/UnpaidIcon';
import { VacationIcon } from '@/components/attendance/icons/VacationIcon';

export const LEAVE_POLICIES_TYPES: {
  label: string;
  value: LeavePolicyType;
  icon: React.FC<{ className: string }>;
  title: string;
  description: string;
  steps: ((props: WizardStepProps) => JSX.Element)[];
}[] = [
  {
    label: 'Vacation',
    value: 'vacation',
    icon: VacationIcon,
    title: 'Vacation Policy',
    description:
      'Time off from work for the purpose of rest, relaxation, and recreation.',
    steps: [LeavePolicyVacationStep01, LeavePolicyAssignmentStep]
  },
  {
    label: 'Sick',
    value: 'sick',
    icon: SickIcon,
    title: 'Sick Policy',
    description:
      'Time off from work granted to employees in the case of illness or injury.',
    steps: [LeavePolicySickStep01, LeavePolicyAssignmentStep]
  },
  {
    label: 'Remote',
    value: 'remote',
    icon: RemoteIcon,
    title: 'Remote Policy',
    description:
      'Allotted days employees can work from home or a remote location',
    steps: [LeavePolicyRemoteStep01, LeavePolicyAssignmentStep]
  },
  {
    label: 'Office',
    value: 'office',
    icon: OfficeIcon,
    title: 'Office Days Policy',
    description:
      'Specific days of the week employees are expected to be present at the office.',
    steps: [LeavePolicyOfficeStep01, LeavePolicyAssignmentStep]
  },
  {
    label: 'Parental',
    value: 'parental',
    icon: ParentalIcon,
    title: 'Parental Policy',
    description:
      'Leave from work for the purpose of caring for newborn or newly adopted children.',
    steps: [LeavePolicyParentalStep01, LeavePolicyAssignmentStep]
  },
  {
    label: 'Bereavement',
    value: 'bereavement',
    icon: BereavementIcon,
    title: 'Bereavement Policy',
    description:
      'Time off granted to employees following the death of a family member or relative.',
    steps: [LeavePolicyBereavementStep01, LeavePolicyAssignmentStep]
  },
  {
    label: 'Personal',
    value: 'personal',
    icon: PersonalIcon,
    title: 'Personal Policy',
    description:
      'Time off from work granted to employees for personal or family needs.',
    steps: [LeavePolicyPersonalStep01, LeavePolicyAssignmentStep]
  },
  {
    label: 'Unpaid',
    value: 'unpaid',
    icon: UnpaidIcon,
    title: 'Unpaid Policy',
    description:
      'An allotted number of unpaid days off work an employee can take.',
    steps: [LeavePolicyUnpaidStep01, LeavePolicyAssignmentStep]
  },
  {
    label: 'Custom',
    value: 'custom',
    icon: CustomIcon,
    title: 'Custom Policy',
    description: 'Click here to write a short description for your policy',
    steps: [LeavePolicyCustomStep01, LeavePolicyAssignmentStep]
  }
];

export function getPolicyDefinition(type: LeavePolicyType) {
  return LEAVE_POLICIES_TYPES.find((policy) => policy.value === type);
}

export function getPolicyIcon(
  type: LeavePolicyType
): React.FC<{ className: string }> {
  const policy = getPolicyDefinition(type);
  if (!policy) {
    console.log(`Unknown policy type ${type} for icon`);
    return CustomIcon;
  }
  return policy.icon;
}

export type IFormPolicy = {
  id: string | null;
  policy_type: LeavePolicyType;
  title: string;
  description: string;
  leaveForm: {
    'allotted-days': 'annually' | 'unlimited';
    'allotted-days-value': number;
    'annual-renewal': 'static' | 'hire-date';
    'annual-renewal-value': Date;
    'wait-period': 'yes' | 'no';
    'wait-period-value': number;
    approval: 'yes' | 'no';
  };
  remote: {
    'allotted-days': 'limited' | 'unlimited';
    'allotted-days-value': number;
    'allotted-days-periodicity': 'weekly' | 'monthly' | 'annually';
    'annual-renewal': 'static' | 'hire-date';
    'annual-renewal-value': Date;
    'wait-period': 'yes' | 'no';
    'wait-period-value': number;
    approval: 'yes' | 'no';
  };
  office: {
    days: {
      [idx in WeekDayNames]: 'weekly' | '1' | '2' | '3' | '4' | 'disabled';
    };
  };
  assignment: {
    offices: string[];
    teams: string[];
    employees: string[];
  };
};

export type GlobalState = {
  policyType: LeavePolicyType | null;
  step: number;
  formPolicies: IFormPolicy;
};

type ReducerActions =
  | {
      type: 'next-step';
    }
  | {
      type: 'prev-step';
    }
  | {
      type: 'update-form';
      payload: IFormPolicy;
    }
  | {
      type: 'set-policy-type';
      payload: LeavePolicyType | null;
    };

export const initialState: GlobalState = {
  policyType: null,
  step: 0,
  formPolicies: {
    id: null,
    title: '',
    description: '',
    policy_type: 'vacation',
    remote: {
      approval: 'yes',
      'annual-renewal': 'static',
      'annual-renewal-value': new Date(new Date().getFullYear(), 0, 1),
      'allotted-days': 'limited',
      'allotted-days-periodicity': 'weekly',
      'allotted-days-value': 1,
      'wait-period': 'no',
      'wait-period-value': 1
    },
    leaveForm: {
      approval: 'yes',
      'annual-renewal': 'static',
      'annual-renewal-value': new Date(new Date().getFullYear(), 0, 1),
      'allotted-days': 'annually',
      'allotted-days-value': 1,
      'wait-period': 'no',
      'wait-period-value': 1
    },
    office: {
      days: {
        monday: 'weekly',
        tuesday: 'weekly',
        wednesday: 'weekly',
        thursday: 'weekly',
        friday: 'weekly',
        saturday: 'weekly',
        sunday: 'weekly'
      }
    },
    assignment: {
      offices: [],
      teams: [],
      employees: []
    }
  }
};

function reducer(state: GlobalState, action: ReducerActions): GlobalState {
  switch (action.type) {
    case 'next-step':
      return { ...state, step: state.step + 1 };
    case 'set-policy-type':
      const policy = action.payload
        ? getPolicyDefinition(action.payload)
        : null;
      return {
        ...state,
        policyType: action.payload,
        step: 0,
        formPolicies: {
          ...state.formPolicies,
          title: policy ? policy.title : '',
          description: policy ? policy.description : ''
        }
      };
    case 'prev-step':
      return { ...state, step: state.step + -1 };
    case 'update-form':
      return { ...state, formPolicies: { ...action.payload } };
    default:
      throw new Error(`Unknown action ${JSON.stringify(action)}`);
  }
}

export type WizardStepProps = {
  state: GlobalState;
  dispatch: React.Dispatch<ReducerActions>;
  officeId: string;
  info: {
    totalSteps: number;
    isLast: boolean;
  };
  closeModal: () => void;
  backToPolicyType: () => void;
};

export function useLeavePoliciesWizard(extraProps: {
  closeModal: () => void;
  editPolicy: IFormPolicy | null;
}) {
  const {
    userContext: { currentOffice }
  } = useAuthenticated();

  const [state, dispatch] = useReducer(
    reducer,
    extraProps.editPolicy
      ? {
          ...initialState,
          formPolicies: extraProps.editPolicy,
          policyType: extraProps.editPolicy.policy_type
        }
      : {
          ...initialState,
          policyType: null
        }
  );
  const policy = state.policyType
    ? getPolicyDefinition(state.policyType)
    : null;
  const CurrentStep = policy?.steps[state.step] ?? LeavePolicySelectTypeStep00;

  const currentProps: WizardStepProps = useMemo(() => {
    const totalSteps = policy?.steps.length ?? 1;
    const isLast = state.step === totalSteps - 1;
    return {
      state,
      officeId: currentOffice.id ?? '',
      dispatch,
      info: { totalSteps, isLast },
      ...extraProps,
      backToPolicyType: () =>
        dispatch({ type: 'set-policy-type', payload: null }),
      closeModal: () => {
        dispatch({ type: 'set-policy-type', payload: null });
        extraProps.closeModal();
      }
    };
  }, [state, dispatch, currentOffice]);
  return { state, dispatch, CurrentStep, currentProps };
}

export function convertPolicyToForm(
  policy: LeavePolicy,
  tz: string
): IFormPolicy {
  const mapValues = {
    weekly: 'weekly',
    month_first: '1',
    month_second: '2',
    month_third: '3',
    month_fourth: '4'
  };
  const days: IFormPolicy['office']['days'] =
    {} as IFormPolicy['office']['days'];
  Object.keys(policy.office_days).forEach((key) => {
    days[key] = policy.office_days[key]
      ? mapValues[policy.office_days[key]]
      : 'disabled';
  });

  const renewalDate = policy.renewal_date
    ? convertTZ(dateParseISO(policy.renewal_date), tz)
    : new Date();
  const formPolicy: IFormPolicy = {
    id: policy.id,
    title: policy.title,
    description: policy.description,
    policy_type: policy.policy_type,
    leaveForm: {
      'allotted-days': policy.allotted === null ? 'unlimited' : 'annually',
      'allotted-days-value': policy.allotted === null ? 1 : policy.allotted,
      'annual-renewal': policy.renewal_on_hire_date ? 'hire-date' : 'static',
      'annual-renewal-value': renewalDate,
      'wait-period': policy.hire_date_minimum_days === null ? 'no' : 'yes',
      'wait-period-value':
        policy.hire_date_minimum_days === null
          ? 1
          : policy.hire_date_minimum_days,
      approval: policy.is_approvable ? 'yes' : 'no'
    },
    remote: {
      'allotted-days': policy.allotted === null ? 'unlimited' : 'limited',
      'allotted-days-value': policy.allotted === null ? 0 : policy.allotted,
      'allotted-days-periodicity':
        policy.renewal === null ? 'weekly' : policy.renewal,
      'annual-renewal': policy.renewal_on_hire_date ? 'hire-date' : 'static',
      'annual-renewal-value': renewalDate,
      'wait-period': policy.hire_date_minimum_days === null ? 'no' : 'yes',
      'wait-period-value':
        policy.hire_date_minimum_days === null
          ? 1
          : policy.hire_date_minimum_days,
      approval: policy.is_approvable ? 'yes' : 'no'
    },
    office: {
      days
    },
    assignment: {
      offices: policy.office_ids ?? [],
      teams: policy.team_ids ?? [],
      employees: policy.user_ids ?? []
    }
  };
  return formPolicy;
}
