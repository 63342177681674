import React from 'react';
import LeavePolicyGenericWithAllotmentStep from '@/components/attendance/admin/wizard/shared/LeavePolicyGenericWithAllotmentStep';
import { WizardStepProps } from '@/components/attendance/admin/wizard/useLeavePoliciesWizard';
import { SickIcon } from '@/components/attendance/icons/SickIcon';
import { UnpaidIcon } from '@/components/attendance/icons/UnpaidIcon';
import { VacationIcon } from '@/components/attendance/icons/VacationIcon';

export default function LeavePolicyUnpaidStep01(props: WizardStepProps) {
  return <LeavePolicyGenericWithAllotmentStep type="unpaid" {...props} />;
}
