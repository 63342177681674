import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

interface MetaHeadProps {
  /**
   * Meta & Open Graph title
   */
  title?: string;
  /***
   * Robots should either be 'follow, index', 'nofollow' or 'none' i.e. 'nofollow, noindex'
   * There are other options but make sure you have a very good use case before adding it
   */
  robots?: 'follow index' | 'nofollow' | 'none';
  /**
   * Meta & Open Graph description
   */
  description?: string;
  /**
   * Open graph types
   */
  ogType?: 'article' | 'book' | 'profile' | 'website';
  /**
   * Open Graph image
   */
  ogImage?: string;
  /**
   * Open Graph image alt text for visually impaired
   */
  ogImageAlt?: string;
  /**
   * Author of the post or article. Please see open graph docs for formatting.
   */
  articleAuthor?: string;
  articleModifyTime?: string;
  articlePublishTime?: string;
  /**
   * Add only if custom URL or breaks the pattern of https://gettactic.com/path i.e. tabs
   */
  pageURL?: string;
  /**
   * URL that we want search engines to index as the primary page
   */
  canonicalURL?: string;
  /**
   * Any additional custom meta information
   */
  children?: React.ReactNode;
}

/**
 * Component for adding meta information to pages, posts,
 * etc. Things like open graph metadata and featured images.
 * Use this component instead of <Head> from next since this component
 * has fallbacks & defaults.
 */
function MetaHead({
  title = 'Where office and remote work meet',
  description = 'Helping proactive companies return to the office and make long-term decisions backed by data.',
  ogType = 'website',
  ogImage = 'https://res.cloudinary.com/tactic/image/upload/v1607208968/og_image_gavs72.png',
  ogImageAlt = 'Tactic helps proactive companies return to the office and make long-term decisions backed by data.',
  articleAuthor,
  articlePublishTime,
  articleModifyTime,
  pageURL,
  canonicalURL,
  children
}: MetaHeadProps): JSX.Element {
  const router = useRouter();

  return (
    <Head>
      <title>{`${title} | Tactic`}</title>
      <meta name="description" content={description} />
      <meta name="author" content="Tactic, Inc." />
      {ogType === 'article' ? (
        <>
          <meta property="og:type" content="article" key="ogType" />
          <meta
            property="article:published_time"
            content={articlePublishTime}
            key="ogArticlePublish"
          />
          <meta
            property="article:modified_time"
            content={articleModifyTime}
            key="ogArticleModify"
          />
          <meta
            property="article:author "
            content={articleAuthor}
            key="ogArticleAuthor"
          />
        </>
      ) : (
        <meta property="og:type" content={ogType} key="ogType" />
      )}
      <meta property="og:site_name" content="Tactic" key="ogSite" />
      <meta property="og:locale" content="en_US" key="ogLocale" />
      <meta property="og:title" content={`${title} | Tactic`} key="ogTitle" />
      <meta property="og:image" content={ogImage} key="ogImg" />
      <meta property="og:image:alt" content={ogImageAlt} key="ogImgAlt" />
      <meta property="og:description" content={description} key="ogDesc" />
      <meta
        property="og:url"
        content={pageURL ? pageURL : `https://gettactic.com${router.asPath}`}
        key="ogUrl"
      />
      {canonicalURL ? (
        <link
          rel="canonical"
          href={
            canonicalURL
              ? canonicalURL
              : `https://gettactic.com${router.asPath}`
          }
        />
      ) : null}
      <meta name="twitter:site" content="@tacticsoftware" key="twSite" />
      <meta name="twitter:card" content="summary_large_image" key="twCard" />
      {children}
    </Head>
  );
}

export default MetaHead;
