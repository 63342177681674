import React from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormRegister
} from 'react-hook-form';
import { classNames } from '@/lib/classNames';
import { Tooltip } from '@/components/basic';

type Props<T extends FieldValues> = {
  className?: string;
  name: Path<T>;
  title: string;
  options: {
    label: string;
    value: string;
    disabled?: boolean;
    hint?: string;
  }[];
  control: Control<T>;
};

export function LeaveRadioOption<T extends FieldValues>({
  control,
  className = 'mt-8',
  title,
  name,
  options
}: Props<T>) {
  return (
    <div className={className}>
      <h4 className="text-lg text-secondary">{title}</h4>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => (
          <div className="mt-2 flex items-center space-x-8">
            {options.map((op) => {
              const valId = op.value;
              return (
                <label
                  key={`${name}_${valId}`}
                  className={classNames(' flex items-center')}
                >
                  <input
                    type="radio"
                    value={op.value}
                    id={`${name}_${valId}`}
                    checked={value === op.value}
                    disabled={op.disabled}
                    onChange={(ev) =>
                      !op.disabled && onChange(ev.currentTarget.value)
                    }
                    className={classNames(
                      op.disabled
                        ? 'cursor-not-allowed text-gray-100'
                        : 'cursor-pointer',
                      `
                        mr-2 checked:bg-primary
                        checked:hover:bg-primary-hover focus:ring-0 focus:ring-primary
                        checked:focus:bg-primary
                        `
                    )}
                  />
                  <span
                    className={classNames(
                      op.disabled
                        ? 'cursor-not-allowed text-gray-500'
                        : 'cursor-pointer'
                    )}
                  >
                    {op.label}
                  </span>
                  {op.hint ? (
                    <div className="flex items-center text-gray-500">
                      <Tooltip>{op.hint ?? ''} </Tooltip>
                    </div>
                  ) : null}
                </label>
              );
            })}
          </div>
        )}
      />
    </div>
  );
}
