import React from 'react';
import {
  FieldValues,
  Path,
  UseFormRegister,
  UseFormWatch
} from 'react-hook-form';
import { Input, Label } from '@/components/basic';

type Props<T extends FieldValues> = {
  className?: string;
  name: Path<T>;
  register: UseFormRegister<T>;
  watch: UseFormWatch<T>;
  min?: number;
  max?: number;
};
export function LeaveDaysInput<T extends FieldValues>({
  register,
  watch,
  className = 'w-40 flex items-center',
  name,
  min = 1,
  max = 365
}: Props<T>) {
  const value = watch(name);
  return (
    <Label className={className}>
      <input
        {...register(name, { valueAsNumber: true })}
        type="number"
        min={min}
        max={max}
        step={1}
        className="type-number:focus:bg-primary rounded-md border-gray-300 py-2 pl-3 leading-normal checked:bg-primary checked:hover:bg-primary-hover focus:border-primary focus:ring-1 focus:ring-primary focus:ring-primary"
      />
      <span className="ml-2">
        {parseInt((value ?? '0').toString(), 10) === 1 ? 'day' : 'days'}
      </span>
    </Label>
  );
}
