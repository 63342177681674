import React from 'react';

type Props = {
  onClick: () => void;
};

export function WizardGoBack({ onClick }: Props) {
  return (
    <div className="absolute top-3 left-1 z-20 sm:left-2.5">
      <a
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          onClick();
        }}
        href="#goBack"
        className="text-md text-trueGray-800 flex items-center font-bold hover:underline"
        title="Go back"
      >
        <svg
          className="h-8 w-8"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </a>
    </div>
  );
}
