import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LeavePolicyType } from '@gettactic/api';
import { DatePicker } from '@/components/basic';
import { NewButton } from '@/components/basic/NewButton/NewButton';
import LoadingSpinner from '@/components/util/LoadingSpinner';
import { LeaveDaysInput } from '@/components/attendance/admin/LeaveDaysInput';
import { LeaveRadioOption } from '@/components/attendance/admin/LeaveRadioOption';
import { LeavePoliciesStepHeader } from '@/components/attendance/admin/wizard/LeavePoliciesStepHeader';
import {
  IFormPolicy,
  WizardStepProps,
  getPolicyIcon
} from '@/components/attendance/admin/wizard/useLeavePoliciesWizard';

type Props = {
  type: LeavePolicyType;
} & WizardStepProps;
export default function LeavePolicyGenericWithAllotmentStep(props: Props) {
  const { dispatch, officeId, state, type } = props;
  const [loading, setLoading] = useState(false);
  const Icon = getPolicyIcon(type);
  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors }
  } = useForm<IFormPolicy>({
    defaultValues: {
      ...state.formPolicies
    }
  });
  const title = watch('title');
  const description = watch('description');
  const anualRenewalWithDate = watch('leaveForm.annual-renewal') === 'static';
  const annualAllotted = watch('leaveForm.allotted-days') === 'annually';
  const hireDateWaitPeriod = watch('leaveForm.wait-period') === 'yes';
  const submit = useCallback(
    async (data: IFormPolicy) => {
      setLoading(true);
      dispatch({
        type: 'update-form',
        payload: { ...state.formPolicies, ...data, policy_type: type }
      });
      dispatch({ type: 'next-step' });
    },
    [officeId]
  );
  const optionsYesNo = useMemo(() => {
    return [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ];
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)} className="max-w-2xl">
      <LeavePoliciesStepHeader
        {...props}
        register={register}
        firstStep={true}
        icon={Icon}
        description={description}
        title={title}
      />

      <LeaveRadioOption
        control={control}
        title="Allotted days"
        name="leaveForm.allotted-days"
        options={[
          { label: 'Annual Allotment', value: 'annually' },
          { label: 'Unlimited', value: 'unlimited' }
        ]}
      />
      {annualAllotted ? (
        <div className="mt-4 flex items-center">
          <span className="">
            <LeaveDaysInput
              name="leaveForm.allotted-days-value"
              watch={watch}
              register={register}
            />
          </span>
        </div>
      ) : null}
      {annualAllotted ? (
        <>
          <LeaveRadioOption
            control={control}
            title="Annual renewal date"
            name="leaveForm.annual-renewal"
            options={[
              { label: 'Static date', value: 'static' },
              {
                label: 'Employee hire date',
                value: 'hire-date',
                disabled: true,
                hint: 'Option available when your organization syncs employee hire date via Directory Sync'
              }
            ]}
          />

          {anualRenewalWithDate ? (
            <Controller
              control={control}
              rules={{ required: anualRenewalWithDate }}
              name="leaveForm.annual-renewal-value"
              render={({ field: { onChange, value } }) => (
                <div className="mt-4">
                  <DatePicker
                    dateFormatCalendar={'MMM'}
                    showYearPicker={false}
                    selected={value}
                    dateFormat="MMMM d"
                    name="leaveForm.annual-renewal-value"
                    className="input rounded-md"
                    calendarClassName="tailwind-datepicker"
                    timeCaption="Date"
                    onChange={onChange}
                  />
                </div>
              )}
            />
          ) : null}
        </>
      ) : null}

      <LeaveRadioOption
        control={control}
        title="Hire date wait period"
        name="leaveForm.wait-period"
        options={[
          {
            label: 'Yes',
            value: 'yes',
            disabled: true,
            hint: 'Option available when your organization syncs employee hire date via Directory Sync'
          },
          { label: 'No', value: 'no' }
        ]}
      />
      {hireDateWaitPeriod ? (
        <div className="mt-4 flex items-center">
          <span className="flex  w-20 items-end">Starts after</span>{' '}
          <LeaveDaysInput
            name="leaveForm.wait-period-value"
            watch={watch}
            register={register}
          />
        </div>
      ) : null}

      <LeaveRadioOption
        control={control}
        title="Requires approval"
        name="leaveForm.approval"
        options={optionsYesNo}
      />

      <div className="flex justify-end">
        <NewButton
          size="md"
          type="submit"
          disabled={loading}
          className="border-1"
        >
          Next Step
          {loading ? <LoadingSpinner className="ml-2" /> : null}
        </NewButton>
      </div>
    </form>
  );
}
