import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ALL_WEEK_DAYS } from '@gettactic/api';
import { classNames } from '@/lib/classNames';
import { NewButton } from '@/components/basic/NewButton/NewButton';
import { SelectMenuSingle } from '@/components/basic/SelectMenu/SelectMenuSingle';
import LoadingSpinner from '@/components/util/LoadingSpinner';
import { LeavePoliciesStepHeader } from '@/components/attendance/admin/wizard/LeavePoliciesStepHeader';
import {
  IFormPolicy,
  WizardStepProps
} from '@/components/attendance/admin/wizard/useLeavePoliciesWizard';
import { OfficeIcon } from '@/components/attendance/icons/OfficeIcon';

export default function LeavePolicyOfficeStep01(props: WizardStepProps) {
  const { dispatch, officeId, state } = props;
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, watch, control, setValue } =
    useForm<IFormPolicy>({
      defaultValues: {
        ...state.formPolicies
      }
    });

  const title = watch('title');
  const description = watch('description');

  const options = [
    { label: 'Weekly', value: 'weekly' },
    { label: '1st %day% of the month', value: '1' },
    { label: '2nd %day% of the month', value: '2' },
    { label: '3rd %day% of the month', value: '3' },
    { label: '4th %day% of the month', value: '4' }
  ];

  const daysValues = watch('office.days');

  const submit = useCallback(
    async (data: IFormPolicy) => {
      setLoading(true);
      //@todo Fill data for update
      console.log('Submit data', { ...data });
      dispatch({
        type: 'update-form',
        payload: { ...state.formPolicies, ...data, policy_type: 'office' }
      });
      dispatch({ type: 'next-step' });
    },
    [officeId]
  );
  return (
    <form onSubmit={handleSubmit(submit)} className="max-w-2xl">
      <LeavePoliciesStepHeader
        {...props}
        register={register}
        firstStep={true}
        icon={OfficeIcon}
        description={description}
        title={title}
      />
      <div className="mt-4 mb-8 flex flex-col space-y-4">
        {ALL_WEEK_DAYS.map((day) => (
          <div className="flex justify-between" key={day}>
            <label
              title={day}
              className={classNames(
                'flex h-10 w-1/2 items-center space-x-2 px-4',
                'cursor-pointer'
              )}
              htmlFor={`checkbox-list-${day}`}
            >
              <input
                onChange={(ev) => {
                  if (ev.currentTarget.checked) {
                    setValue(`office.days.${day}`, 'weekly');
                  } else {
                    setValue(`office.days.${day}`, 'disabled');
                  }
                }}
                id={`checkbox-list-${day}`}
                type="checkbox"
                checked={daysValues[day] !== 'disabled'}
                className="rounded checked:bg-primary checked:hover:bg-primary-hover focus:ring-0 focus:ring-primary checked:focus:bg-primary"
              />
              <span className="truncate capitalize">{day}</span>
            </label>

            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectMenuSingle
                  className="w-3/4"
                  readonly={daysValues[day] === 'disabled'}
                  setValue={onChange}
                  options={options.map((x) => ({
                    ...x,
                    label: x.label.replace('%day%', day)
                  }))}
                  value={value}
                />
              )}
              name={`office.days.${day}`}
            />
          </div>
        ))}
      </div>

      <div className="flex justify-end">
        <NewButton
          size="md"
          type="submit"
          disabled={loading}
          className="border-1"
        >
          Next Step
          {loading ? <LoadingSpinner className="ml-2" /> : null}
        </NewButton>
      </div>
    </form>
  );
}
