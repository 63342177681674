export function OfficeIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="19"
      height="26"
      viewBox="0 0 19 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.98356 5.17045C4.98356 4.73097 5.32258 4.375 5.74114 4.375H8.01386C8.43053 4.375 8.77144 4.73097 8.77144 5.17045V7.55682C8.77144 7.99432 8.43053 8.35227 8.01386 8.35227H5.74114C5.32258 8.35227 4.98356 7.99432 4.98356 7.55682V5.17045ZM14.0745 4.375C14.4911 4.375 14.832 4.73097 14.832 5.17045V7.55682C14.832 7.99432 14.4911 8.35227 14.0745 8.35227H11.8017C11.3851 8.35227 11.0442 7.99432 11.0442 7.55682V5.17045C11.0442 4.73097 11.3851 4.375 11.8017 4.375H14.0745ZM4.98356 11.5341C4.98356 11.0966 5.32258 10.7386 5.74114 10.7386H8.01386C8.43053 10.7386 8.77144 11.0966 8.77144 11.5341V13.9205C8.77144 14.358 8.43053 14.7159 8.01386 14.7159H5.74114C5.32258 14.7159 4.98356 14.358 4.98356 13.9205V11.5341ZM14.0745 10.7386C14.4911 10.7386 14.832 11.0966 14.832 11.5341V13.9205C14.832 14.358 14.4911 14.7159 14.0745 14.7159H11.8017C11.3851 14.7159 11.0442 14.358 11.0442 13.9205V11.5341C11.0442 11.0966 11.3851 10.7386 11.8017 10.7386H14.0745ZM0.816895 3.18182C0.816895 1.42436 2.17343 0 3.8472 0H15.9684C17.6398 0 18.9987 1.42436 18.9987 3.18182V22.2727C18.9987 24.0277 17.6398 25.4545 15.9684 25.4545H3.8472C2.17343 25.4545 0.816895 24.0277 0.816895 22.2727V3.18182ZM3.08962 3.18182V22.2727C3.08962 22.7102 3.42864 23.0682 3.8472 23.0682H7.63508V19.8864C7.63508 18.5689 8.65307 17.5 9.9078 17.5C11.1625 17.5 12.1805 18.5689 12.1805 19.8864V23.0682H15.9684C16.3851 23.0682 16.726 22.7102 16.726 22.2727V3.18182C16.726 2.74233 16.3851 2.38636 15.9684 2.38636H3.8472C3.42864 2.38636 3.08962 2.74233 3.08962 3.18182Z"
        fill="currentColor"
      />
    </svg>
  );
}
