export function UnpaidIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 1.5121C2.50734 1.5121 1.5 2.52756 1.5 3.78024V8.31653C1.5 8.73236 1.16438 9.07258 0.75 9.07258C0.335812 9.07258 0 8.73236 0 8.31653V3.78024C0 1.6926 1.67906 0 3.75 0H26.25C28.3219 0 30 1.6926 30 3.78024V8.31653C30 8.73236 29.6625 9.07258 29.25 9.07258C28.8375 9.07258 28.5 8.73236 28.5 8.31653V3.78024C28.5 2.52756 27.4922 1.5121 26.25 1.5121H3.75ZM23.25 4.53629C23.6625 4.53629 24 4.87651 24 5.29234V21.5474C24 23.0075 22.8234 24.1935 21.375 24.1935H8.625C7.17656 24.1935 6 23.0075 6 21.5474V5.29234C6 4.87651 6.3375 4.53629 6.75 4.53629C7.1625 4.53629 7.5 4.87651 7.5 5.29234V18.1452C9.98438 18.1452 12 20.1345 12 22.6815H18C18 20.1345 19.9734 18.1452 22.5 18.1452V5.29234C22.5 4.87651 22.8375 4.53629 23.25 4.53629ZM10.5 22.6815C10.5 21.0134 9.15469 19.6573 7.5 19.6573V21.5474C7.5 22.1758 8.00156 22.6815 8.625 22.6815H10.5ZM22.5 21.5474V19.6573C20.8453 19.6573 19.5 21.0134 19.5 22.6815H21.375C21.9984 22.6815 22.5 22.1758 22.5 21.5474ZM15 17.3891C12.1922 17.3891 9.75 15.4801 9.75 12.8528C9.75 10.2256 12.1922 8.31653 15 8.31653C17.7656 8.31653 20.25 10.2256 20.25 12.8528C20.25 15.4801 17.7656 17.3891 15 17.3891ZM11.25 12.8528C11.25 14.398 12.7547 15.877 15 15.877C17.2031 15.877 18.75 14.398 18.75 12.8528C18.75 11.3076 17.2031 9.82863 15 9.82863C12.7547 9.82863 11.25 11.3076 11.25 12.8528Z"
        fill="currentColor"
      />
    </svg>
  );
}
