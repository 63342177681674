import { useMemo } from 'react';
import useTeams from '@/hooks/useTeams';
import SelectMenu from '@/components/basic/SelectMenu/SelectMenu';

type Props = {
  onChange: (ids: string[]) => void;
  teams?: string[];
  includeFavs: boolean;
  className?: string;
  applyOnClick?: boolean;
};

export const SelectTeamsBeta2 = ({
  className,
  onChange,
  teams = [],
  includeFavs = false,
  applyOnClick
}: Props) => {
  const { teamList } = useTeams({ type: 'directory', hidden: false });
  const { teamList: groups } = useTeams({ type: 'group', hidden: false });

  const options = useMemo(() => {
    const elements = (teamList ? teamList.elements : []).concat(
      groups && includeFavs ? groups.elements : []
    );
    const options = elements.map((x) => ({ label: x.name, value: x.id }));
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
  }, [teamList, groups, includeFavs]);

  return (
    <>
      {teamList && groups ? (
        <SelectMenu
          className={className}
          nonePlaceholder={`${includeFavs ? 'Teams & Lists' : 'All Teams'}`}
          allPlaceholder={`${includeFavs ? 'Teams & Lists' : 'All Teams'}`}
          options={options}
          values={teams}
          onChange={onChange}
          applyOnClick={applyOnClick}
        />
      ) : null}
    </>
  );
};
