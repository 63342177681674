import { useQuery } from '@tanstack/react-query';
import { ITeamType } from '@gettactic/api';
import { api } from '@/lib/api/api';

type Params = {
  type: ITeamType | 'no-fetch';
  query?: string;
  hidden?: boolean;
};

export default function useTeams(params: Params) {
  const {
    data: teamList,
    error,
    isFetching
  } = useQuery(
    [
      'organizations.teamsWithOptions',
      params.type,
      params.hidden,
      params.query
    ],
    async () => {
      const { type } = params;
      const res = await api.client.teams.all({
        ...params,
        type: type as ITeamType
      });
      return res.result;
    },
    { enabled: params.type !== 'no-fetch' }
  );

  return { teamList, error, isFetching };
}
