import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@/components/basic';
import { NewButton } from '@/components/basic/NewButton/NewButton';
import { SelectMenuSingle } from '@/components/basic/SelectMenu/SelectMenuSingle';
import LoadingSpinner from '@/components/util/LoadingSpinner';
import { LeaveDaysInput } from '@/components/attendance/admin/LeaveDaysInput';
import { LeaveRadioOption } from '@/components/attendance/admin/LeaveRadioOption';
import { LeavePoliciesStepHeader } from '@/components/attendance/admin/wizard/LeavePoliciesStepHeader';
import {
  IFormPolicy,
  WizardStepProps
} from '@/components/attendance/admin/wizard/useLeavePoliciesWizard';
import { RemoteIcon } from '@/components/attendance/icons/RemoteIcon';

export default function LeavePolicyRemoteStep01(props: WizardStepProps) {
  const { dispatch, officeId, state } = props;
  const [loading, setLoading] = useState(false);

  const { handleSubmit, register, watch, control } = useForm<IFormPolicy>({
    defaultValues: { ...state.formPolicies }
  });
  const title = watch('title');
  const description = watch('description');
  const anualRenewalWithDate = watch('remote.annual-renewal') === 'static';
  const limitedAllotment = watch('remote.allotted-days') === 'limited';
  const hireDateWaitPeriod = watch('remote.wait-period') === 'yes';
  const allottedDaysPeriodicityIsAnnual =
    watch('remote.allotted-days-periodicity') === 'annually';
  const submit = useCallback(
    async (data: IFormPolicy) => {
      setLoading(true);
      //@todo Fill data for update
      console.log('Submit data', { ...state.formPolicies });
      dispatch({
        type: 'update-form',
        payload: { ...state.formPolicies, ...data, policy_type: 'remote' }
      });
      dispatch({ type: 'next-step' });
    },
    [officeId]
  );

  const options = [
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Annually', value: 'annually' }
  ];

  return (
    <form onSubmit={handleSubmit(submit)} className="max-w-2xl">
      <LeavePoliciesStepHeader
        {...props}
        register={register}
        firstStep={true}
        icon={RemoteIcon}
        description={description}
        title={title}
      />

      <LeaveRadioOption
        control={control}
        title="Allotted days"
        name="remote.allotted-days"
        options={[
          { label: 'Limited allotment', value: 'limited' },
          { label: 'Unlimited', value: 'unlimited' }
        ]}
      />
      {limitedAllotment ? (
        <div className="mt-4 flex items-center space-x-4">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectMenuSingle
                className="w-32"
                setValue={onChange}
                options={options}
                value={value}
              />
            )}
            name="remote.allotted-days-periodicity"
          />
          <LeaveDaysInput
            name="remote.allotted-days-value"
            watch={watch}
            register={register}
          />
        </div>
      ) : null}
      {limitedAllotment && allottedDaysPeriodicityIsAnnual ? (
        <>
          <LeaveRadioOption
            control={control}
            title="Annual renewal date"
            name="remote.annual-renewal"
            options={[
              { label: 'Static date', value: 'static' },
              {
                label: 'Employee hire date',
                value: 'hire-date',
                disabled: true,
                hint: 'Option available when your organization syncs employee hire date via Directory Sync'
              }
            ]}
          />

          {anualRenewalWithDate ? (
            <Controller
              control={control}
              rules={{ required: anualRenewalWithDate }}
              name="remote.annual-renewal-value"
              render={({ field: { onChange, value } }) => (
                <div className="mt-4">
                  <DatePicker
                    dateFormatCalendar={'MMM'}
                    showYearPicker={false}
                    selected={value}
                    dateFormat="MMMM d"
                    name="annual-renewal-value"
                    className="input rounded-md"
                    calendarClassName="tailwind-datepicker"
                    timeCaption="Date"
                    onChange={onChange}
                  />
                </div>
              )}
            />
          ) : null}
        </>
      ) : null}

      <LeaveRadioOption
        control={control}
        title="Hire date wait period"
        name="remote.wait-period"
        options={[
          {
            label: 'Yes',
            value: 'yes',
            disabled: true,
            hint: 'Option available when your organization syncs employee hire date via Directory Sync'
          },
          { label: 'No', value: 'no' }
        ]}
      />
      {hireDateWaitPeriod ? (
        <div className="mt-4 flex items-center">
          <span className=" flex  w-20 items-end">Starts after</span>{' '}
          <LeaveDaysInput
            name="remote.wait-period-value"
            watch={watch}
            register={register}
          />
        </div>
      ) : null}

      <LeaveRadioOption
        control={control}
        title="Requires approval"
        name="remote.approval"
        options={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' }
        ]}
      />
      <div className="flex justify-end">
        <NewButton
          size="md"
          type="submit"
          disabled={loading}
          className="border-1"
        >
          Next Step
          {loading ? <LoadingSpinner className="ml-2" /> : null}
        </NewButton>
      </div>
    </form>
  );
}
