import React from 'react';
import SimpleBar, { Props } from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useSimpleBarRefresher } from '@/hooks/useSimpleBarRefresher';

type ScrollableProps = React.HTMLAttributes<HTMLElement> &
  Props & {
    maxHeight?: string | number;
    refreshAfterInstall?: number;
  };

const Scrollable = ({
  refreshAfterInstall = 700,
  maxHeight,
  ...rest
}: ScrollableProps): JSX.Element => {
  const { ref } = useSimpleBarRefresher();
  return <SimpleBar ref={ref} style={{ maxHeight: maxHeight }} {...rest} />;
};

export default Scrollable;
