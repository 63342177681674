import React from 'react';
import {
  LEAVE_POLICIES_TYPES,
  WizardStepProps
} from '@/components/attendance/admin/wizard/useLeavePoliciesWizard';

export default function LeavePolicySelectTypeStep00({
  dispatch
}: WizardStepProps) {
  return (
    <>
      <div className="my-0 text-gray-600">
        <h4 className="mx-auto mb-8 max-w-lg text-center text-2xl">
          What type of policy would you like to create?
        </h4>
        <div className="grid grid-cols-3 place-content-evenly place-items-center gap-4">
          {LEAVE_POLICIES_TYPES.map((policyType) => (
            <button
              key={policyType.value}
              className="flex h-28 w-36 flex-col items-center justify-center rounded-md border border-gray-200 text-primary"
              onClick={() =>
                dispatch({ type: 'set-policy-type', payload: policyType.value })
              }
            >
              <span>
                <policyType.icon className="h-14 w-14" />
              </span>
              <span className="text-lg">{policyType.label}</span>
            </button>
          ))}
        </div>
      </div>
    </>
  );
}
